<template>
    <Login />
</template>

<script>
import Login from '@/components/auth/Login.vue'

export default {
    name: 'home',
    components: {
        Login,
    },
}
</script>
