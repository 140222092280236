<template>
    <loading-screen />
</template>

<script>
import LoadingScreen from './Loading.vue'

export default {
    components: {
        LoadingScreen,
    },
}
</script>
